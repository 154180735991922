/* eslint-disable no-unused-vars */
import React, { useRef, Fragment, useEffect, useState } from 'react';
import { useTranslations } from '@veraio/strank';
import { isNil } from 'lodash-es';
import { Row, Col, NewsBox, Button, showError, useWindowSize, useUrlParams } from 'components';
import { getNews, getNewsCategories } from 'services';
import { filterModel, getTranslations } from 'utils';
import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import { wrap, tabsWrap, scrollButtonsWrap, listWrap, footer } from './styles';

const NewsList = () => {
  const { getText, language } = useTranslations();
  const windowWidth = useWindowSize(window.innerWidth, window.innerHeight);
  const { urlQueryParams } = useUrlParams();
  const { preview } = urlQueryParams;
  const [news, setNews] = useState(null);
  const [newsCategories, setNewsCategories] = useState(null);
  const scrollRef = useRef();
  const bufferNumber = windowWidth.width <= 767 ? 1 : windowWidth.width <= 991 ? 2 : 3;

  useEffect(() => {
    fetchNewsCategories();
  }, []);

  useEffect(() => {
    language && fetchNews(1);
  }, [language?.code]);

  const fetchNews = async (page, options) => {
    const filter = filterModel({
      ...(options?.categoryId && { categoryId: `categoryId="${options?.categoryId}"` }),
      ...(!preview && { isActive: 'isActive=true' }),
      ...(language?.code === 'zh' && { chinaRestricted: 'chinaRestricted=false' }),
    });

    const [res, err] = await getNews({
      page,
      perPage: 20,
      sort: '-createdAt',
      filter,
      fields: `id,collectionId,image,title,description,createdAt,${getTranslations(
        ['title', 'description'],
        language?.code,
      )}`,
    });
    if (err) return showError(err?.data);

    setNews((prev) =>
      isNil(prev) || page === 1
        ? { ...res, categoryId: options?.categoryId }
        : {
            ...res,
            categoryId: options?.categoryId,
            items: [...prev.items, ...res.items],
          },
    );
  };

  const fetchNewsCategories = async () => {
    const [res, err] = await getNewsCategories({
      filter: '(isVisible=true)',
      perPage: 100,
      skipTotal: true,
    });
    if (err) return showError(err?.data);
    setNewsCategories(res);
  };

  const scrollRight = () => {
    scrollRef.current.scrollLeft += 120;
  };

  const scrollLeft = () => {
    scrollRef.current.scrollLeft -= 120;
  };

  const handleChangeCategory = (id) => {
    if (id === news?.categoryId) return;
    fetchNews(1, { categoryId: id });
  };

  const handleMore = () => {
    const nextPage = news?.page + 1;
    fetchNews(nextPage);
  };

  return (
    <section css={wrap}>
      <h4>{getText('latestArticles')}</h4>
      <div css={scrollButtonsWrap}>
        <div role="button" tabIndex={0} className="scroll-button" onClick={scrollLeft}>
          <ChevronLeft />
        </div>
        <div role="button" tabIndex={0} className="scroll-button" onClick={scrollRight}>
          <ChevronRight />
        </div>
      </div>

      <div css={tabsWrap} ref={scrollRef}>
        <Button noHover outline onClick={handleChangeCategory} {...(!news?.categoryId && { type: 'secondary' })}>
          {getText('allProducts')}
        </Button>
        {newsCategories?.items?.map((el) => (
          <Button
            noHover
            outline
            onClick={() => handleChangeCategory(el?.id)}
            key={el?.id}
            {...(news?.categoryId === el?.id && { type: 'secondary' })}>
            {getText(el?.name)}
          </Button>
        ))}
      </div>
      <div css={listWrap}>
        <Row>
          {news?.items?.map((el, i) => (
            <Fragment key={el?.id}>
              <Col md={6} lg={4}>
                <NewsBox data={el} />
              </Col>
              {(i + 1) % bufferNumber === 0 && (
                <Col md={12} lg={12}>
                  <div className="list-buffer" />
                </Col>
              )}
            </Fragment>
          ))}
        </Row>
      </div>

      <div css={footer}>
        {news?.page < news?.totalPages && (
          <Button onClick={handleMore} type="gray">
            {getText('moreNews')}
          </Button>
        )}
      </div>
    </section>
  );
};

export default NewsList;
