import { useState, useEffect } from 'react';
import { useTranslations } from '@veraio/strank';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash-es';
import { useParams, Link } from 'react-router-dom';
import { Row, Col, Breadcrumbs, Icon, LatestNews, showError } from 'components';
import { getNewsDetails } from 'services';
import { dateFormat, getImagePath, getTranslations, getTranslationData } from 'utils';
import { header, body, separator } from './styles';

const NewsDetails = ({ history }) => {
  const { getText, language } = useTranslations();
  const params = useParams();
  const newsId = params?.newsId;
  const [newsData, setNewsData] = useState();

  const items = [
    {
      label: getText('news'),
      url: '/news',
    },
    {
      label: getTranslationData(newsData, 'title', language?.code),
    },
  ];

  useEffect(() => {
    language && fetchNews();
  }, [newsId, language?.code]);

  const fetchNews = async () => {
    const [res, err] = await getNewsDetails(newsId, {
      fields: `id,collectionId,categoryId,image,title,description,content,createdAt,chinaRestricted,${getTranslations(
        ['title', 'description', 'content'],
        language?.code,
      )}`,
    });
    if (err) return showError(err?.data);
    if (language?.code === 'zh' && res?.chinaRestricted) return history.push('/news');

    setNewsData(res);
  };

  return (
    <>
      <section css={header(getImagePath(newsData))}>
        <div className="header-inner">
          <Breadcrumbs items={items} className="header-breadcrumbs" />
          <Link to="/news">
            <div className="go-back-to-all">
              <Icon iconName="la la-arrow-left" />
              <p>{getText('allNews')}</p>
            </div>
          </Link>

          <Row justify="space-between" horizontalGap={16} className="content-row">
            <Col lg={6} xxl={5} xxxl={5}>
              {newsData && (
                <>
                  <h2 className="news-title">{getTranslationData(newsData, 'title', language?.code)}</h2>
                  <p className="news-text">{getTranslationData(newsData, 'description', language?.code)}</p>
                  {!isEmpty(newsData?.createdAt) && (
                    <p className="news-date">
                      {getText('published')}: {dateFormat(newsData?.createdAt)}
                    </p>
                  )}
                </>
              )}
            </Col>
            <Col lg={6} xxl={6} xxxl={5}>
              <div className="news-image" />
            </Col>
          </Row>
        </div>
      </section>
      <section css={body}>
        <Row container>
          <Col>
            <div
              className="news-content"
              dangerouslySetInnerHTML={{ __html: getTranslationData(newsData, 'content', language?.code) }}
            />
          </Col>
        </Row>
      </section>
      <div css={separator} />
      {newsData?.categoryId && <LatestNews titleKey="relatedNews" noSlider categoryId={newsData?.categoryId} />}
    </>
  );
};

LatestNews.propTypes = {
  history: PropTypes.object,
};

export default NewsDetails;
